import { message } from 'antd';
import { Toast } from 'antd-mobile';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FROM } from '../store/enmu';

const host = 'https://h5.xzyby.cn';
// const host = 'http://39.97.188.204:8880';
// const host = 'http://192.168.0.180:8080';
// const host = 'http://192.168.9.15:8080';
// const host = 'http://seaeagle.tpddns.cn:8081';
// const host = 'http://192.168.8.161:8080';

var errorshow = false
const errorMap: {[key: number]: Function} = {
    603: () => {
        if(!errorshow) {
            // Toast.info('请登录');
            // 3秒内只弹出一次提示
            errorshow = true;
            setTimeout(() => {
                errorshow = false
            }, 3000)
        }
        if(localStorage.getItem('noLoginURL') === null) {
            localStorage.clear();
            localStorage.setItem('noLoginURL', window.location.href);
            // window.location.href = window.location.href.split('#')[0] + "#/accountLogin";
            window.location.href = window.location.href.split('#')[0] + "#/main/home";
        }
        // window.location.reload();
    },
    300: (msg: string) => {
        if(msg!=="注册失败，您已使用其他手机号进行过注册") {
            Toast.info(msg);
        }
    }
}

async function get(url: string,data?: any) {
    var requestUrl = url;
    if(data) {
        requestUrl += '?';
        for(let key in data) {
            if(data[key] !== undefined) {
                requestUrl = requestUrl + key + '=' + data[key] + '&';
            }
        }
    }
    let res = await axios.get(host + requestUrl, {
        headers: {
            access_token: localStorage.getItem('userToken')
        }
    });
    if(res.data.code === 200) {
        return res.data;
    } else {
        // todo统一处理异常
        errorMap[res.data.code] && errorMap[res.data.code](res.data.message);
        return res.data
    }
};

async function post(url: string,data: any) {
    let res = await axios.post(host + url, data, {
        headers: {
            access_token: localStorage.getItem('userToken')
        }
    });
    if(res.data.code === 200) {
        return res.data;
    } else {
        // todo统一处理异常
        errorMap[res.data.code] && errorMap[res.data.code](res.data.message);
        return res.data
    }
};

async function postFile(url: string,data: any) {
    let res = await axios.post(host + url, data, {
        headers: {
            access_token: localStorage.getItem('userToken')
        }
    })
    if(res.status=== 200) {
        return res.data
    } else {
        // todo统一处理异常
        return undefined
    }
};

async function all(list: Promise<AxiosResponse<any>>[]) {
    let res = await axios.all(list);
    res.forEach(result => {
        // todo统一处理异常
        if(result.data.code !== 200) {
            errorMap[result.data.code] && errorMap[result.data.code](result.data.message);
        }
    })
    return res.map(result => (result.data));
}

function getQuest(url: string,data?: any) {
    var requestUrl = url;
    if(data) {
        requestUrl += '?';
        for(let key in data) {
            if(data[key] !== undefined) {
                requestUrl = requestUrl + key + '=' + data[key] + '&';
            }
        }
    }
    return axios.get(host + requestUrl, {
        headers: {
            access_token: localStorage.getItem('userToken')
        }
    });
}

function postQuest(url: string,data: any) {
    return axios.post(host + url, data, {
        headers: {
            access_token: localStorage.getItem('userToken')
        }
    });
};

async function postData(url: string,obj: any) {
    let data = new FormData();
    for(let key in obj) {
        data.append(key, obj[key]);
    }
    let res = await axios.post(host + url, data, {
        headers: {
            access_token: localStorage.getItem('userToken')
        }
    });
    if(res.data.code === 200) {
        return res.data;
    } else {
        errorMap[res.data.code] && errorMap[res.data.code](res.data.message);
        return res.data;
    }
};
export { get,post,getQuest,postQuest,all,postData,postFile }
