import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Input, Select, Button, message, Checkbox, Tooltip } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import { get, post, postData } from "../../../axios/axios";
import { login } from "../../../store/actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../../store/type";
import "./index.css";
import { Icon, Toast, Modal } from "antd-mobile";
import loginbackground from '../../../asset/loginbackground.png';
import { FROM } from "../../../store/enmu";
import { APPResult } from "../PhoneLogin";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { optionToast } from "../../../components/ImportComponent";
import wx from 'weixin-js-sdk'
import axios from "axios";

interface IProps extends RouteComponentProps, LoginInfo {
  onLogin: (userInfo: UserInfo, userToken: string) => {};
}

interface IState {
  areaCode: string,
  phoneNumber: string,
  password: string,
  agree: Boolean,
  showAgree: Boolean,
  isShowUserAgreement: boolean,
  isShowtermsOfService: boolean,
  isIOS: boolean,
  isShowTooltip: boolean,
}

const mapStateToProps = (state: LoginInfo): {
  padding?: string,
  from: FROM | null,
  appid: string,
} => ({
  padding: state.padding,
  from: state.from,
  appid: state.appid
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (userInfo: UserInfo, userToken: string) =>
    dispatch(login(userInfo, userToken)),
});

declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
class AccountLogin extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      areaCode: "+86",
      phoneNumber: (localStorage.getItem("loginPhone") === null ? '' : localStorage.getItem("loginPhone") as string),
      password: "",
      agree: false,
      showAgree: true,
      isShowUserAgreement: false,
      isShowtermsOfService: false,
      isIOS: false,
      isShowTooltip: false
    };
    if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
      this.wxWapLogin();
    }
    if (props.from === FROM.APP && window['jsGetPlatForm']) {
      this.getPhone();
    }
  }
  getPhone() {
    const that = this;
    window['setPlatForm'] = function (msg: string) {
      if (msg === 'IOS') {
        that.setState({
          isIOS: true
        })
      } else if (msg === 'Android') {
      }
    }
    window['jsGetPlatForm'].postMessage('');
  }
  async wxWapLogin() {
    debugger
    var url = window.location.href.split('#')[0]
    if (url.indexOf('?') !== -1) {
      var param = url.split('?')[1]
      var kvList = param.split('&')
      var code = '';
      kvList.forEach((kvStr: string) => {
        let kv = kvStr.split('=');
        if (kv[0] === 'code') {
          code = kv[1]
        }
      })
      let userinfo = await get('/api/user/verifyByWxPublicCode', {
        code: code
      })
      if (userinfo.code === 200) {
        localStorage.setItem("userToken", userinfo.message);
        this.props.onLogin(userinfo.data, userinfo.message);
        if (localStorage.getItem('redirectURL') !== null) {
          this.props.history.push(localStorage.getItem('redirectURL') as string)
          localStorage.removeItem('redirectURL');
          localStorage.removeItem('noLoginURL');
        } else if (localStorage.getItem('noLoginURL') !== null) {
          window.location.href = localStorage.getItem('noLoginURL');
          localStorage.removeItem('redirectURL');
          localStorage.removeItem('noLoginURL');
        }
        Toast.info("登录成功");
      } else if (userinfo.code === 400 && userinfo.data.wxUnionid !== null) {
        Toast.info('首次登录请验证手机号', 3);
        localStorage.setItem('wxWapOpenid', userinfo.data.publicOpenid);
        localStorage.setItem('wxWapUnionid', userinfo.data.wxUnionid);
        this.props.history.replace('/phoneLogin');
      } else if (userinfo.code === 400 && userinfo.data.wxUnionid === null) {
        Toast.info('获取微信信息失败');
      }
      // localStorage.setItem('wxWapCode', kv[1])
      // this.props.history.replace('/phoneLogin');
      // Toast.info('首次登录请验证手机号');
    } else {
      window.location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.props.appid + '&redirect_uri=' + encodeURIComponent(window.location.href) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect');
    }
  }
  onChange(e: CheckboxChangeEvent) {
    this.setState({
      agree: e.target.checked
    })
    if (e.target.checked) {
      this.setState({
        isShowTooltip: false
      })
    }
  }
  render() {
    // console.log(this.state)
    const { Option } = Select;
    let top = 0;
    if (this.props.padding && this.props.padding !== null) {
      top = JSON.parse(this.props.padding).top
    }
    return (
      <div className="accountLogin">
        <div className="accountLoginBGV" style={{ background: 'url(' + loginbackground + ') no-repeat', backgroundSize: '100% 100%' }}></div>
        {/* <video id="backgroundVideo" className="accountLoginBGV" controls={false} autoPlay loop muted>
          <source src="./loginVideo/logvid.mp4" type="video/mp4"></source>
        </video> */}
        {/* <div className="accountLoginBackground"></div> */}
        <div className="accountLoginDiv" style={{ paddingTop: top }}>
          <Icon
            className="back"
            type={"left"}
            // onClick={() => this.props.history.go(-2)}
            onClick={() => this.props.history.replace("/main/home")}
          />
          <Button
            type="text"
            className="toAccountBtn"
            onClick={() => this.props.history.replace("/phoneLogin")}
          >
            免密登录
          </Button>
          <p className="loginMethodText">密码登录</p>
          {/* <p className="loginMethodTextRemark">
            本机号码未注册将自动创建新账号
          </p> */}
          <div className="loginPhoneNum">
            {/* <Select
              size="large"
              className="loginPhoneSel"
              defaultValue="+86"
              onChange={this.saveAreaCode}
            >
              <Option value="+86">+86</Option>
            </Select> */}
            <Input
              size="large"
              className="loginPhoneInput"
              placeholder="输入手机号"
              allowClear
              defaultValue={this.state.phoneNumber}
              onChange={this.saveDataToState("phoneNumber")}
            ></Input>
          </div>
          <Input.Password
            placeholder="输入密码"
            size="large"
            className="loginCodeInput"
            allowClear
            onChange={this.saveDataToState("password")}
          ></Input.Password>
          <Button size="large" className="loginBtn" onClick={this.loginOnClick}>
            登录
          </Button>

          <div style={{ marginTop: "5%" }}>
            <div style={{ display: 'inline-block', marginRight: "2%" }}>
              <Tooltip title="点此勾选方可注册登录" visible={this.state.isShowTooltip}>
                <Checkbox className="agreementText" onChange={(e: CheckboxChangeEvent) => this.onChange(e)} />
              </Tooltip>
            </div>
            <div style={{ display: 'inline-block' }}>
              <div>勾选即同意徐州园博园
                <a href="javascript:void(0);" onClick={this.GoToUserAgreement} style={{ color: "#048779" }}>
                  《隐私政策》
                </a>
              </div>
            </div>
          </div>

          <br />
          <div className="registerBox">
            {/* 没有账号？去
            <Button
              type="text"
              className="registerBtn"
              onClick={() => this.props.history.push('/register')}
            >
              注册
            </Button> */}
            <Button
              type="text"
              className="forgetBtn"
              onClick={this.forgetOnClick}
            >
              忘记密码？
            </Button></div>

          <br />
          {this.props.from === FROM.APP && !this.state.isIOS ?
            <>
              <div className="wechatIconText"><div></div><span>或使用微信</span><div></div></div>
              <WechatOutlined style={{ fontSize: '25px' }} className="wechatIcon" onClick={() => this.wxLogin()} />
            </>
            : null}

          <Modal
            visible={this.state.isShowUserAgreement}
            transparent
            maskClosable={false}
            title="隐私政策"
            className="abc"
            footer={[{ text: '关闭', onPress: () => { this.CloseUserAgreement(); } }]}>
            <div style={{ textAlign: "left" }}>
              <div style={{ fontWeight: "bold" }}>欢迎您访问第十三届（徐州）国际园林博览官方网络平台！</div>
              <div style={{ fontWeight: "bold" }}>本网络平台由第十三届（徐州）国际园林博览（后称徐州园博园）会运营并负责提供相关产品服务。如果用户在本网络平台访问、预定或使用徐州园博园的产品或服务（以上统称为“服务”），便视为用户接受了以下隐私政策，请您仔细阅读以下内容，尤其是以下加粗字体。如果您不同意以下任何内容，请立刻停止访问/使用本网络平台。本隐私政策中提及的“徐州园博园”以及“我们”均指徐州园博园官方网络平台，“用户”以及“您”均指自愿接受本隐私政策的会员用户。</div>
              <div >
                1、 隐私政策的确认和接纳<br />
                2、 信息收集<br />
                3、 信息使用<br />
                4、 信息共享、转让和披露<br />
                5、 信息保存<br />
                6、 Cookie的使用<br />
                7、 个人敏感信息提示<br />
                8、 信息安全与保护<br />
                9、 信息安全事件处置<br />
                10、 未成年人信息保护<br />
                11、 用户个人信息管理<br />
                12、 从中国大陆地区以外访问我们的网站<br />
                13、 隐私政策的适用范围<br />
                14、 隐私政策的修改<br />
                15、 联系我们<br />
                16、 法律管辖<br />
                17、脸部图像存储<br />
              </div>
              <div style={{ fontWeight: "bold" }}>1、 隐私政策的确认和接纳<br /></div>

              <span >尊重用户个人隐私是徐州园博园的一项基本政策。我们会将第十三届（徐州）国际园林博览的旅游服务信息发布于该网络平台,以供用户查阅，同时帮助用户联系、咨询、预订相关旅游服务。</span>
              <span style={{ fontWeight: "bold" }}>您在使用我们的产品、服务前，请仔细阅读并确认您已经充分理解本隐私政策所写明的内容，同意与徐州园博园达成协议且接受所有的服务条款。 徐州园博园作为徐州园博园官方网络平台的信息控制者，</span>
              <span>我们十分注重保护用户的个人信息及隐私安全，我们理解您通过网络向我们提供信息是建立在对我们信任的基础上，我们重视这种信任，也深知隐私对您的重要性，将尽最大努力保护您的隐私。</span>

              <div style={{ fontWeight: "bold" }}>2、 信息收集<br /></div>
              <span style={{ fontWeight: "bold" }}>（1）为了更好地为您提供服务，徐州园博园将在您授权同意的前提下，遵循“合法、正当、必要”原则收集您的个人信息。您知悉并同意，徐州园博园将会收集您在徐州园博园官方网络平台上或以其他方式提供给我们的所有信息：<br /></span>
              <span>a.当您注册徐州园博园用户时，至少需提供电话号码并设置密码以创建徐州园博园账号。<br /></span>
              <span>b.当您预订徐州园博园酒店、民宿客栈时，您至少需提供</span><span style={{ fontWeight: "bold" }}>全部入住人姓名、身份证件号码及联系方式</span><span>信息。<br /></span>
              <span>c.当您预订徐州园博园景区门票、套餐或游玩服务时，根据不同服务的类别，您可能需提供不同的信息类别，这些信息包括但不限于</span><span style={{ fontWeight: "bold" }}>姓名、证件类型、证件号码、证件签发地、出生地、出生日期、联系电话、国籍、性别、护照号码、发证机关、发证日期、护照类型、护照有效期、签发日期、港澳通行证签注类型</span><span>等信息。<br /></span>
              <span>d.若您需要开具纸质或电子发票，您还需提供包括</span><span style={{ fontWeight: "bold" }}>发票抬头、纳税人识别号、地址、电话号码、开户行、银行卡号等</span><span>开票信息</span><span style={{ fontWeight: "bold" }}>以及收件人、电子邮箱</span><span>信息。<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;1. 当您进行支付结算时，您需提供</span><span style={{ fontWeight: "bold" }}>银行卡号、开户行、银行预留手机号、持卡人姓名、持卡人身份证件、验证码</span><span><br />信息。</span>
              <span>f.当您的同行人中包含未成年人时，我们需要您作为监护人提供出行的未成年人的</span>
              <span style={{ fontWeight: "bold" }}>姓名、身份证件等</span>
              <span>信息。<br /></span>
              <span>g.当您联系我们的客服以获得我们的用户服务时，还需提供您的</span>
              <span style={{ fontWeight: "bold" }}>用户资料</span>
              <span>和</span>
              <span style={{ fontWeight: "bold" }}>订单信息</span>
              <span>来验证身份。<br /></span>
              <span>h.若您需要更新您的个人信息，则应该符合及时、准确、详尽的原则。<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;1.i. 请您知悉并同意，我们还可能了解您的旅行计划、消费和喜好，包括住宿/膳食要求、同行人员、工作单位、出行/入离时间、服务产品选择以及徐州园博园提供的其他服务（如索道、用车、游船、攻略等）所需要您提供的个人信息。<br /></span>
              <span style={{ fontWeight: "bold" }}>（2）您可以通过我们为其他人预订，但您需提交该用户的个人信息，向我们提供该用户的个人信息之前，须确保您已经取得本人的同意，并确保其已知晓并接受本隐私政策。<br /></span>
              <span style={{ fontWeight: "bold" }}>（3）在您使用我们服务的过程中，我们将自动收集您的个人信息：<br /></span>
              <span>a.日志信息，指您使用我们的服务时，系统可能通过cookies、web beacon或其他方式自动采集的信息。<br /></span>
              <span>b.位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您的位置信息。我们收集地理位置的信息包括GPS或WLAN的方式，您可以通过关闭定位功能，停止我们对您的地理位置信息的收集。<br /></span>
              <span style={{ fontWeight: "bold" }}>（4）我们会从关联公司、业务合作方获得您的相关信息。例如当您通过我们关联公司、业务合作方的网站及其移动应用软件等进行预订时，为确保您顺利预定，您提供的预订信息可能会被转交给我们，以便我们处理您的订单。徐州园博园将依据与关联公司、业务合作方的约定，对这些个人信息来源的合法性进行确认。<br /></span>
              <span style={{ fontWeight: "bold" }}>（5）为提升您的旅游体验，我们可能会基于您授权的移动设备操作系统的相关权限来收集和使用您的个人信息（具体以您的实际授权为准）。如果您不提供此类个人信息，您依然可以完成我们的预订、入住等基本服务。<br /></span>
              <span style={{ fontWeight: "bold" }}>3、 信息使用<br /></span>
              <span style={{ fontWeight: "bold" }}>（1）徐州园博园一般出于以下目的，收集、使用您的个人信息： <br /></span>
              <span>1.为您提供服务：帮助您完成网上预订、退款等。另外，我们也会通过关联公司、业务合作方和第三方为您提供相关的服务。<br /></span>
              <span>2.帐号管理：您可以通过徐州园博园创建帐号，我们将使用您所提供的信息来管理您的帐号，并为您提供一系列的实用功能，您可以使用您的帐号进行多种操作，如管理订单、调整个人设置、查看历史订单信息、订单评价、支付管理等。<br /></span>
              <span>3.提供客服帮助：我们将提供客户服务，以便在您需要的时候提供帮助。<br /></span>
              <span>4.营销活动：我们也会使用您的信息进行正当合法的营销活动，如向您发送徐州园博园相关的产品或服务的最新消息、向您提供您可能感兴趣的个性化推荐及其他推广活动信息。<br /></span>
              <span>5.与您联系：回复及处理您所提出的疑问或要求、发送与订单相关的信息（如订单提交成功提示、未完成订单的提醒等）、我们也可能会向您发送问卷调查或邀请您对我们的服务提供反馈等。<br /></span>
              <span>6.市场调研：我们可能会邀请您参与市场调查，以衡量您对我们的产品、服务和网站的兴趣<br /></span>
              <span>7.提升服务的安全性和可靠性：我们可能会检测和预防欺诈行为、不法活动，将您的个人数据用于风险评估和安全目的。<br /></span>
              <span>8.数据分析：我们可能将您的订单数据用于分析，从而形成用户画像，以便让我们能够了解您所在的位置、偏好和人口统计信息，或与其他来源（包括第三方）的数据相匹配，从而开发我们的产品、服务或营销计划，改进我们的服务。<br /></span>
              <span>9.控制信用风险：为了确保交易安全和控制信用风险，徐州园博园及关联公司、业务合作方可能对用户信息进行数据分析，以便我们更好的服务用户。<br /></span>
              <span>10.日常运营：包括但不限于订单管理、客户验证、技术支持、网络维护、故障排除、内部行政事务及内部政策和程序、生成内部报告等。<br /></span>
              <span>11.电话监测：您接听和拨打我们的客服电话可能会被录音，我们可能会使用通话录音来监控我们的客服服务质量，检查您所提供的信息的准确性以防止欺诈，或为了我们内部人员培训之目的。录音在被保留一段时间后会自动删除，除非由于合规要求或合法利益需要为场景的保留。<br /></span>
              <span>12.人脸数据：采集人脸数据，是为了用于园博园内AI步道，采集的数据用于我们app内使用，不做其它使用。<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;l.履行义务：处理相关规定下发生的保险索赔和付款，处理支付给合作方的佣金或对服务合作方造成的损失提起索赔或收回付款等。<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;m.法律目的：我们可能需要使用您的信息来处理和解决法律纠纷，或遵循对我们具有约束力的任何法律法规或监管机构颁发的文件规定，以配合国家部门或监管机构调查和遵从法律法规的目的。<br /></span>
              <span>此外，我们可能会出于其他目的收集、使用和披露您的个人信息，并通过修订本政策的形式告知您。<br /></span>
              <span style={{ fontWeight: "bold" }}>（2）您在享受徐州园博园提供的各项服务的同时，授权并同意接受徐州园博园向您的电子邮箱、手机、通信地址等发送商业信息，包括但不限于徐州园博园最新的产品信息、促销信息等。若您选择不接受徐州园博园提供的各类信息服务，您可通过相应设置拒绝该类信息服务。<br /></span>
              <span style={{ fontWeight: "bold" }}>（3）您充分知晓，根据相关法律法规及国家标准，以下情形中我们使用个人信息无需征得您的授权同意：<br /></span>
              <span>1.与国家安全、国防安全有关的；<br /></span>
              <span>2.与公共安全、公共卫生、重大公共利益有关的；<br /></span>
              <span>3.与犯罪侦查、起诉、审判和判决执行等有关的；<br /></span>
              <span>4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br /></span>
              <span>5.所收集的个人信息是个人信息主体自行向社会公众公开的；<br /></span>
              <span>6.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br /></span>
              <span>7.根据您的要求签订和履行合同所必需的；<br /></span>
              <span>8.用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；<br /></span>
              <span>9.为合法的新闻报道所必需的；<br /></span>
              <span>10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br /></span>
              <span>11.法律法规规定的其他情形。<br /></span>
              <span style={{ fontWeight: "bold" }}>4、信息共享、转让和公开披露 <br /></span>
              <span style={{ fontWeight: "bold" }}>（1）我们可能会向关联公司、业务合作方等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以确保为您顺利提供服务。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作方包括以下类型（包含中国境内和中国境外实体）：<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;1.金融机构和第三方支付机构：当您预订订单、申请退款时，我们会与金融机构或第三方支付机构共享特定的订单信息，当我们认为用于欺诈检测和预防目的实属必要时，我们将进一步和相关金融机构共享其他必要信息，如IP地址等。<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;2.业务合作方：我们可能与合作方一起为您提供产品或者服务，包括快递业务、通讯服务、客户服务、市场推广、广告投放、技术服务、实名认证服务、咨询服务等。<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;3.关联公司：我们可能会与我们的关联公司共享您的个人信息，使我们能够向您提供相关的产品、服务信息。<br /></span>
              <span>对于我们与之共享个人信息的合作方，我们会与其签署保密条款。他们无权将共享的个人信息用于任何其他用途，如要改变个人信息的处理目的，合作方将再次征求您的授权同意。<br /></span>
              <span style={{ fontWeight: "bold" }}>（2）信息转让<br /></span>
              <span style={{ fontWeight: "bold" }}>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br /></span>
              <span>1.事先获得您的明确同意或授权；<br /></span>
              <span>2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；<br /></span>
              <span>3.在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。<br /></span>
              <span style={{ fontWeight: "bold" }}>（3）公开披露<br /></span>
              <span style={{ fontWeight: "bold" }}>我们仅会在以下情形，公开披露您的个人信息：<br /></span>
              <span>a.根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；<br /></span>
              <span>b.根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。<br /></span>
              <span style={{ fontWeight: "bold" }}>5、信息保存及跨境传输<br /></span>
              <span>（1）您的个人信息我们将妥善保存，除非法律规定或发生不可抗力等情形，可能会对您的信息进行删除或匿名化等处理。<br /></span>
              <span>（2）如我们停止运营徐州园博园产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。<br /></span>
              <span>（3）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，部分场景需要将您的个人信息传输至境外：<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;a.获得您的明确授权；<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;b.当您通过互联网进行跨境交易等个人主动行为；<br /></span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;c.法律法规的明确要求。<br /></span>
              <span style={{ fontWeight: "bold" }}>6、Cookie的使用<br /></span>
              <span>为确保网站正常运转，让您获得更轻松便捷的访问、使用体验，我们可能会在您的设备终端或系统上存储名为Cookie的小型数据文件用来识别您的身份，这样可以帮您省去重复输入注册信息的步骤，优化对产品服务的选择与互动并帮助判断您的账户安全状态。</span>
              <span style={{ fontWeight: "bold" }}>。您有权通过默认或修改浏览器设置选择接受或拒绝Cookie。请注意，如果您选择拒绝Cookie，那么您可能无法完全体验徐州园博园提供的服务。<br /></span>
              <span style={{ fontWeight: "bold" }}>7、个人敏感信息提示<br /></span>
              <span>个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中涉及的个人敏感信息包括：个人财产类信息（包括</span>
              <span style={{ fontWeight: "bold" }}>行帐号、验证信息、交易和消费记录、虚拟财产信息</span>
              <span>、个人健康医疗信息、个人生物识别信息（</span>
              <span style={{ fontWeight: "bold" }}>脸部图像、脸部视频</span>
              <span>）、个人身份信息（</span>
              <span style={{ fontWeight: "bold" }}>包括身份证、护照、台胞证、回乡证、军人证、驾驶证、户口簿、出生证明、外国人永久居留身份证、港澳台居民居住证、照片、婚姻证明、关系证明、在职证明、单位证明</span>
              <span>）、网络身份标识信息（包括</span>
              <span style={{ fontWeight: "bold" }}>账户名、邮箱地址</span>
              <span>及与前述有关的</span>
              <span style={{ fontWeight: "bold" }}>密码、验证保护</span>
              <span>），以及其他信息（包括</span>
              <span style={{ fontWeight: "bold" }}>个人电话号码、婚史、行踪轨迹、住宿信息、精准定位信息、种族信息、宗教信息</span>
              <span>）。<br /></span>
              <span>这些个人敏感信息将受到严格保护，徐州园博园隐私政策在此提醒您，您在使用徐州园博园为您提供的产品及服务（如点评等）中所发布的信息可能会披露您的个人敏感信息，而这些信息并不一定是提供服务所必需收集的信息。</span>
              <span style={{ fontWeight: "bold" }}>因此，您需要在使用我们为您提供的产品或服务前谨慎考虑。您知悉并同意这些个人敏感信息将按照《徐州园博园隐私政策》阐明的目的和方式来进行处理。<br /></span>
              <span style={{ fontWeight: "bold" }}>8、信息安全与保护 <br /></span>
              <span>（1）徐州园博园非常重视保护您的信息安全，我们采用了专门为保护访客信息不受非授权访问、披露、使用以及修改而设计的技术、行政管理及实质安全措施，以维护您的个人信息的完整性、保密性和可用性。<br /></span>
              <span>（2）我们与可能接触到您信息的员工均已签署保密协议，并通过网络系统权限限制的形式，最大程度降低可能接触到您个人信息的员工人数，以保护您的个人信息。<br /></span>
              <span>（3）尽管有前述的安全措施，但同时也请您理解在网络上不存在“完善的安全措施”。我们会按现有的技术提供相应的安全措施来保护您的信息，提供合理的安全保障。<br /></span>
              <span>（4）您的帐户均有安全保护功能，请妥善保管您的帐号及密码信息，切勿将密码告知他人，如果您发现自己的个人信息泄露，特别是您的帐号和密码发生泄露，请您立即与我们联系，以便我们采取相应的措施。<br /></span>
              <span>（5）请您及时保存或备份相关文字、图片等信息，理解并接受，在您接入我们服务所用的系统和通讯网络时，可能出现我们可控范围外的问题。 <br /></span>
              <span>（6）在使用徐州园博园官方网络平台进行交易时，请您妥善保护自己的个人信息（包括但不限于出行人姓名、联络方式或联系地址），仅在必要的情形下向他人提供。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您提供的信息安全性<br /></span>
              <span style={{ fontWeight: "bold" }}>9、信息安全事件处置 <br /></span>
              <span>若不幸发生个人信息安全事件，我们会按照法律法规的要求，及时采取补救措施，并将以邮件、信函、电话、推送通知等方式告知您事件的相关情况，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，及时上报个人信息安全事件的处置情况。<br /></span>
              <span style={{ fontWeight: "bold" }}>10、未成年人信息保护 <br /></span>
              <span>(1) 徐州园博园非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用徐州园博园的服务前，应事先取得法定监护人的同意。徐州园博园根据《中华人民共和国未成年人保护法》等国家相关法律法规的要求对未成年人的个人信息及隐私进行保护。<br /></span>
              <span>(2) 徐州园博园不会主动、直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们仅在法律法规允许、监护人同意或保护未成年人所必要的情况下使用、共享、转让或披露此类信息。<br /></span>
              <span>(3) 作为监护人，您可以在登录账号后，自行对未成人的相关信息进行查询、更正和删除操作。如果有事实证明未成年人在并未取得监护人同意的情况下注册和使用了我们的服务，监护人可以联系徐州园博园客服，我们会在确认后尽快予以处理。<br /></span>
              <span>(4) 对于不满14周岁的儿童个人信息，我们还会遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，按照《儿童个人信息网络保护规定》等法律法规的要求进行收集、存储、使用、转移、披露。当您作为监护人为被监护的儿童选择使用徐州园博园相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用评价功能时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。如您对儿童个人信息相关事宜有任何意见、建议或投诉，请联系我们。<br /></span>
              <span style={{ fontWeight: "bold" }}>11、用户个人信息管理 <br /></span>
              <span>（1）您可以查询、更正、删除及注销自己的账户信息，修改个人资料、隐私设置、安全设置，修改收货地址等您所提供给我们的个人信息。<br /></span>
              <span>（2）您可以通过删除您的个人信息、关闭设备功能等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权<br /></span>
              <span>（3）您可以自行登录网站注销徐州园博园账户。注销徐州园博园账户后，您账户内的所有信息将被清空。有关注销账号的相关规则和流程，请参见本政策附件中的《徐州园博园账户注销协议》。<br /></span>
              <span style={{ fontWeight: "bold" }}>12、从中国大陆地区以外访问我们的网站<br /></span>
              <span>如果您从中国大陆以外地区访问我们网站，请您注意，您的信息可能被传送至、存储于中国大陆，并且在中国大陆进行处理。中国大陆的数据保护法和其他法律可能与您所在国家/地区的法律不同，但请相信我们将采取措施保护您的信息。选择使用我们的服务的同时，您了解并同意您的信息可能被传送至我们的网站，及如本隐私政策所述的与我们共享信息的第三方。<br /></span>
              <span style={{ fontWeight: "bold" }}>13、隐私政策的适用范围 <br /></span>
              <span>我们所有的服务均适用《徐州园博园隐私政策》，特定服务还需适用特定的隐私政策或协议。当特定服务的隐私政策或协议与《徐州园博园隐私政策》不一致时，须适用特定服务的隐私政策或协议。<br /></span>
              <span>请您注意，《徐州园博园隐私政策》不适用于以下情况：通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；通过在我们服务中进行广告服务的其他公司和机构所收集的信息。<br /></span>
              <span style={{ fontWeight: "bold" }}>14、隐私政策的修改<br /></span>
              <span>请您理解，我们可能会在必要时适时修订本隐私政策。未经您明确同意，徐州园博园不会削减您按照本隐私权政策所应享有的权利。对于重大变更，徐州园博园还会提供更为显著的通知（例如对于某些服务，我们可能会通过电子邮件发送通知，说明隐私权政策的具体变更内容）。请您经常回访本隐私政策，以阅读最新版本。<br /></span>
              <span style={{ fontWeight: "bold" }}>15、联系我们<br /></span>
              <span>（1）如您有任何与个人信息保护或隐私保护相关的问题、意见或建议，您可以通过联系网站资讯建议资讯我们或发送邮件xzxsyby@126.com至我们或拨打我们的客服电话88888888等多种方式与我们联系。<br /></span>
              <span>（2）请您知悉并理解，一般情况下，我们将在十五个工作日内予以回复。<br /></span>
              <span style={{ fontWeight: "bold" }}>16、法律管辖<br /></span>
              <span>本隐私政策之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本隐私政策产生的争议，均应依照中华人民共和国法律予以处理，并由被告住所地人民法院管辖。<br /></span>
              <span style={{ fontWeight: "bold" }}>17、脸部图像存储<br /></span>
              <span>人脸信息存储在天翼云的存储平台上。人脸数据仅供本app使用，不与第三方共享。<br /></span><span><br /></span>
              <span><br /></span>
              <span><br /></span>
              <span><br /></span>
              <span style={{ fontWeight: "bold" }}>附件：《徐州园博园账户注销协议》<br /></span>
              <span>亲爱的徐州园博园用户：<br /></span>
              <span>在您注销您的账户之前，请充分阅读、理解并同意下列事项：<br /></span>
              <span>在此善意地提醒您，您注销徐州园博园账户的行为，将导致我们终止对您提供相关服务，也会给您的售后维权带来诸多不便。请您知晓并理解，注销成功后，我们将在后台妥善保存您的个人信息。除非法律规定或发生不可抗力等情形，我们可能会对您的信息进行删除或匿名化等处理。<br /></span>
              <span>当您按照注销流程填写或提供信息、阅读并同意《徐州园博园账户注销协议》及相关条款与条件，并完成注销流程后，即表示您已充分阅读、理解并接受本《徐州园博园账户注销协议》的全部内容。阅读本《徐州园博园账户注销协议》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。<br /></span>
              <span>如果您仍执意注销账户，您的账户需同时满足以下条件：<br /></span>
              <span>1、无未完成的订单。<br /></span>
              <span>2、无未使用的积分、卡券等用户已购买权益。<br /></span>
              <span>3、无其他账户被限制的记录。<br /></span>
              <span>在徐州园博园账户注销期间，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查，徐州园博园有权自行终止账户的注销而无需另行得到您的同意。
                徐州园博园账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。注销徐州园博园账户，您将无法再使用本徐州园博园账户，也将无法找回您徐州园博园账户中及与账户相关的内容或信息。您理解并同意，徐州园博园无法协助您重新恢复前述服务。<br /></span>
              <span>注销本徐州园博园账户，并不代表本徐州园博园账户注销前的账户行为和相关责任得到豁免或减轻。<br /></span>
            </div>
          </Modal>
          <Modal
            visible={this.state.isShowtermsOfService}
            transparent
            maskClosable={false}
            title="服务条款"
            footer={[{ text: '关闭', onPress: () => { this.ColseTermsOfService(); } }]}>
            <div>服务条款内容</div>
          </Modal>
        </div>
      </div>
    );
  }

  wxLogin() {
    if (this.state.agree) {
      const that = this
      window['setWxLogin'] = function (msg: any) {
        that.wxGo(msg);
      }
      window['jsWxLogin'].postMessage('');
    } else {
      this.setState({
        isShowTooltip: true
      });
      let maxTime = 3;
      let waitTimer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
        } else {
          clearInterval(waitTimer);
          this.setState({
            isShowTooltip: false
          });
        }
      }, 1000);
      // optionToast.show('点此勾选方可注册登录', 5000, ['5vw','calc(100vh - 28vw)'], 'optionToast', '4vw')
    }
  }
  async wxGo(msg: string) {
    let appResult = JSON.parse(msg) as APPResult;
    let api = new FormData();
    api.append('s', 'msg------' + msg)
    axios.post('http://39.97.188.204:8880/api/v1/outLog', api);
    if (appResult.openid && appResult.unionid) {
      let res = await postData('/api/user/registeredByWX', {
        openId: appResult.openid,
        unionid: appResult.unionid
      });
      let api = new FormData();
      api.append('s', 'result------' + JSON.stringify(res))
      axios.post('http://39.97.188.204:8880/api/v1/outLog', api);
      if (res.code === 400) {
        Toast.info('首次登录请验证手机号', 3)
        this.props.history.replace('/phoneLogin')
        localStorage.setItem('wxOpenId', appResult.openid)
        localStorage.setItem('wxUnionId', appResult.unionid)
      } else if (res.code === 200) {
        Toast.info("登录成功");
        localStorage.setItem("userToken", res.message);
        this.props.onLogin(res.data, res.message);
        if (localStorage.getItem('jumpLink') != null) {
          this.props.history.push(localStorage.getItem('jumpLink') as string)
        } else {
          this.props.history.push('/main/mine')
        }
        localStorage.removeItem('jumpLink');
        // this.props.history.push('/main/mine')
        // this.props.history.go(-1);
      }
    } else {
      Toast.info('微信授权失败')
    }
  }
  loginOnClick = async () => {
    const { phoneNumber, password } = this.state;
    if (phoneNumber == "") {
      Toast.info('请填写手机号')
      return
    }
    if (password == "") {
      Toast.info('请填写密码')
      return
    }
    if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phoneNumber)) {
      Toast.info('手机号格式错误')
      return
    }
    if (this.state.agree) {
      const { phoneNumber, password } = this.state;
      let subData = {
        phone: phoneNumber,
        password: password,
      };
      console.log(subData);
      let res = await post("/api/user/userlogin", subData);
      if (res.code === 200) {
        Toast.info("登录成功");
        localStorage.setItem("userToken", res.message);
        localStorage.setItem('loginPhone', subData.phone as string)
        this.props.onLogin(res.data, res.message);
        if (localStorage.getItem('noLoginURL') !== null) {
          window.location.href = localStorage.getItem('noLoginURL');
          localStorage.removeItem('noLoginURL')
        } else {
          // this.props.history.go(-1)
          // let pageRouter = localStorage.getItem('redirectURL')
          if (localStorage.getItem('jumpLink') != null) {
            this.props.history.push(localStorage.getItem('jumpLink') as string)
          } else {
            this.props.history.push('/main/mine')
          }
          localStorage.removeItem('jumpLink');
        }
      } else {
        // Toast.info("密码错误");
      }
    } else {
      this.setState({
        isShowTooltip: true
      });
      let maxTime = 3;
      let waitTimer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
        } else {
          clearInterval(waitTimer);
          this.setState({
            isShowTooltip: false
          });
        }
      }, 1000);
      // optionToast.show('点此勾选方可注册登录', 5000, ['5vw','calc(100vh - 28vw)'], 'optionToast', '4vw')
    }
  };

  forgetOnClick = () => {
    this.props.history.push('/phoneEditPassword')
    // const { phoneNumber } = this.state;
    // console.log(phoneNumber);
    // Toast.info("忘记密码");
  };

  saveAreaCode = (data: any) => {
    this.setState({
      areaCode: data,
    });
  };
  GoToTermsOfService = () => {
    this.setState({
      isShowtermsOfService: true
    });
  };
  ColseTermsOfService = () => {
    console.log("Close!!!!!")

    this.setState({
      isShowtermsOfService: false
    });
  }
  GoToUserAgreement = () => {
    this.setState({
      isShowUserAgreement: true
    });
  }
  CloseUserAgreement = () => {
    console.log("Close!!!!!")
    this.setState({
      isShowUserAgreement: false
    });
  }

  saveDataToState = (dataType: string) => {
    return (event: any) => {
      this.setState({
        ...this.state,
        [dataType]: event.target.value,
      });
    };
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps>>(AccountLogin));
