import React, { Component, ComponentClass } from "react";
import { PageHeader, Button, Avatar, message } from "antd";
import { Modal } from "antd-mobile";
import counselBackground from "../../asset/counsel/counselBackground.png";
import style from "./index.module.css";
import "./index.css";
import { LoginInfo, UserInfo } from "../../store/type";
import { Dispatch } from "redux";
import { login } from "../../store/actions";
import { connect } from "react-redux";
import face0 from "../../asset/DefaultFace/face0.png";
import face1 from "../../asset/DefaultFace/face1.png";
import face2 from "../../asset/DefaultFace/face2.png";
import { RouteComponentProps, withRouter } from "react-router-dom";
import integralIcon from "../../asset/mine/integralIcon.png";
import giftIcon from "../../asset/mine/giftIcon.png";
import adviceIcon from "../../asset/mine/adviceIcon.png";
import orderIcon from "../../asset/mine/orderIcon.png";
import findIcon from "../../asset/mine/findIcon.png";
import settingIcon from "../../asset/mine/setting.png";
import complaintIcon from "../../asset/mine/complaintIcon.png";
import background from "../../asset/mine/background.png";
import yuyue from "../../asset/mine/yuyue.png";
import { QrcodeOutlined, RightOutlined } from "@ant-design/icons";
import { FROM, LOGINSTATE } from "../../store/enmu";
import shadow from '../../asset/mine/shadow.png';
import { get, post } from "../../axios/axios";
interface IProps extends LoginInfo, RouteComponentProps {}
interface IState {
  point: string,
  amountsNum: number
}
interface IAmounts {
    coupAmt: string,
    coupId: string,
    coupName: string,
    couponType: string,
    lastDate: string,
    sendDate: string,
    sta: string,
    ticketCode: string
}
const alert = Modal.alert;

declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new (): Window;
};
const WeixinJSBridge = window["WeixinJSBridge"];

const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  state?: LOGINSTATE;
  from: FROM;
  userToken?: string;
} => ({
  userInfo: state.userInfo,
  state: state.state,
  from: state.from as FROM,
  userToken: state.userToken
});
const faceList = [face0, face1, face2];
var wx = require('weixin-js-sdk');
class Mine extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      point: '0',
      amountsNum: 0
    }    
    if (props.state !== LOGINSTATE.SIGNIN) {
      // this.props.history.push("/accountLogin");
      this.props.history.push('/main/mine')
    }
    this.getData();
  }
  async getData() {
    let vipInfo = await post('/api/user/vipQueryApi', {mobileNo: this.props.userInfo?.phone});
    if(vipInfo.code === 200) {
      if(vipInfo.data.resultCode === 'v001') {
        let addRetailinfo = await post('/api/user/addRetailinfo', this.props.userInfo);
        if(addRetailinfo.code === 200){
          this.getData();
        }
      } else {
        this.setState({
          point: parseInt(vipInfo.data.point).toString()
        })
      }
    }
    let res = await post('/api/user/vipCouponQueryApi', {
      mobileNo: this.props.userInfo?.phone
    });
    if(res.code === 200) {
        this.setState({
          amountsNum: res.data.couponList.filter((coupon: IAmounts) => (coupon.sta === '0')).length
        });
    }
  }
  jump(url: string) {
    console.log(this.props)
    if(this.props.from === FROM.WXMINI) {
      wx.miniProgram.navigateTo({
        url: '../jumpPage/jumpPage?url=' + `https://h5.xzyby.cn/#/wxminiOpen/${this.props.userToken}/${encodeURIComponent(url)}`,
      });
    } else {
      this.props.history.push(url)
    }
  }
  render() {
    const { amountsNum, point } = this.state
    const { userInfo, state } = this.props;
    const faceUrl =
      userInfo && userInfo.avatar && userInfo.avatar !== null
        ? userInfo.avatar
        : faceList[userInfo && userInfo.sex ? userInfo.sex : 0];

    // const alert = Modal.alert
    return (
      <div className={style.Counsel}>
        <div className={style.mineTop} style={{background: 'url("' + background + '") no-repeat',backgroundSize: '100% 100%'}}>
          <div className={style.userInfo} onClick={() => this.jump("/userInfo")}>
            <div className={style.headImage} style={{background: 'url(' + (faceUrl ? faceUrl : faceList[0]) + ') no-repeat', backgroundPosition: 'center', backgroundSize: '100% auto'}}>
              {/* <img src={faceUrl ? faceUrl : faceList[0]} alt="" width="100%" height="auto" /> */}
            </div>
            <div className={style.userNameBox}>
              <div className={style.userName}>{userInfo?.username}</div>
              <div className={style.scole} onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}>
                <span>积分</span>
                <span className={style.number}>{point}</span>
              </div>
              <div className={style.indentity} onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  this.jump('/amounts')
                  // this.props.history.push('/amounts');
                }}>
                <span>礼金券</span>
                <span className={style.number}>{amountsNum}</span>
              </div>
              <QrcodeOutlined style={{margin: '4vw 0 0 10vw', width: '5vw'}}  onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  this.jump('/paycode')
                  // this.props.history.push('/paycode')
                }}/>
              <RightOutlined  style={{margin: '4vw 0 0 0vw', width: '5vw',fontSize: '3.2rem', lineHeight: 1.5}}/>
            </div>
          </div>
        </div>
        <div className={style.mineBody}>
          {/* <div className={style.countBox}>
            <div>
              <img src={integralIcon} alt="" />
              积分
              <span className={style.bold}>-</span>
            </div>
            <div>
              <img src={giftIcon} alt="" />
              礼金券
              <span className={style.bold}>-</span>
            </div>
          </div> */}
          <div className={[style.sysBox,style.list].join(' ')}>
            <div className={style.sysBoxLine}
              onClick={() => this.jump("/myOrder")}>
              <img src={orderIcon} alt="" />
              <span>我的订单</span>
              <RightOutlined style={{color: '#999'}}/>
            </div>
            <div className={style.line}></div>
            <div className={style.sysBoxLine}
              onClick={() => this.jump("/reservationList")}>
              <img src={yuyue} alt="" />
              <span>我的预约</span>
              <RightOutlined style={{color: '#999'}}/>
            </div>
            <div className={style.line}></div>
            <div className={style.sysBoxLine}
              onClick={() => this.jump("/find")}>
              <img src={findIcon} alt="" />
              <span>寻人寻物</span>
              <RightOutlined style={{color: '#999'}}/>
            </div>
            <div className={style.line}></div>
            <div className={style.sysBoxLine}
              onClick={() => this.jump("/suggestionList")}>
              <img src={adviceIcon} alt="" />
              <span>咨询建议</span>
              <RightOutlined style={{color: '#999'}}/>
            </div>
            <div className={style.line}></div>
            <div className={style.sysBoxLine}
              onClick={() => this.jump("/complaintList")}>
              <img src={complaintIcon} alt="" />
              <span>投诉举报</span>
              <RightOutlined style={{color: '#999'}}/>
            </div>
          </div>
          {this.props.from === FROM.APP ? (
          <div className={[style.sysBox,style.list].join(' ')}>
            <div className={style.sysBoxLine}
              onClick={() => this.jump("/setting")}>
              <img src={settingIcon} alt="" />
              <span>设置</span>
              <RightOutlined style={{color: '#999'}}/>
            </div>
          </div>): null}
        </div>

        {/* <div className={style.body}>
          <div
            className={style.headImage}
            onClick={() => this.props.history.push("/userInfo")}
          >
            <img src={faceUrl ? faceUrl : faceList[0]} alt="" />
          </div>
          <div className={style.userName}>{userInfo?.username}</div>
          <div className={style.item}>
            <div className={style.countBox}>
              <div>
                <img src={integralIcon} alt="" />
                积分
                <span className={style.bold}>-</span>
                <RightOutlined />
              </div>
              <div>
                <img src={giftIcon} alt="" />
                礼金券
                <span className={style.bold}>-</span>
                <RightOutlined />
              </div>
            </div>
            <div
              className={style.list}
              onClick={() => this.props.history.push("/myOrder")}
            >
              <img src={orderIcon} alt="" />
              <span>我的订单</span>
              <RightOutlined />
            </div>
            <div
              className={style.list}
              onClick={() => this.props.history.push("/find")}
            >
              <img src={findIcon} alt="" />
              <span>寻人寻物</span>
              <RightOutlined />
            </div>
            <div
              className={style.list}
              onClick={() => this.props.history.push("/suggestionList")}
            >
              <img src={adviceIcon} alt="" />
              <span>咨询建议</span>
              <RightOutlined />
            </div>
            <div
              className={style.list}
              onClick={() => this.props.history.push("/complaintList")}
            >
              <img src={complaintIcon} alt="" />
              <span>投诉举报</span>
              <RightOutlined />
            </div>
            {this.props.from === FROM.APP ? (
              <div
                className={style.list}
                onClick={() => this.props.history.push("/setting")}
              >
                <img src={settingIcon} alt="" />
                <span>设置</span>
                <RightOutlined />
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(Mine)
);
