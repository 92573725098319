import { Button, Modal, Toast } from 'antd-mobile';
import Form from 'antd/lib/form/Form';
import axios from 'axios';
import React, { ComponentClass, FunctionComponent } from 'react';
import { Dispatch } from "redux";
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import wx from 'weixin-js-sdk';
import { get, post } from '../../axios/axios';
import { login } from '../../store/actions';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import SvgLog from '../SvgLog';
import style from './index.module.css';

const alert = Modal.alert;
const mapStateToProps = (state: LoginInfo): {
    from: FROM | null
    userInfo?: UserInfo
    state: LOGINSTATE
    appid: string
} => ({
    from: state.from,
    userInfo: state.userInfo,
    state: state.state,
    appid: state.appid
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onLogin: (userInfo: UserInfo, userToken: string) =>
        dispatch(login(userInfo, userToken)),
});

interface IEntrance {
    icon: string,
    title: string,
    url: string,
    delFlag: Number
}
interface IState {
    thumeStyle: React.CSSProperties | undefined
}
export interface IEntranceProps {
    entranceList: IEntrance[]
}
interface IProps extends RouterProps, LoginInfo {
    entranceList: IEntrance[];
    onLogin: (userInfo: UserInfo, userToken: string) => {};
}
const scrollRef = React.createRef<HTMLDivElement>();
var scrollTimeout: NodeJS.Timeout;

declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new(): Window;
};
class Entrance extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            thumeStyle: {
                width: (4 * 10 / props.entranceList.slice(0, Math.ceil(props.entranceList.length / 2)).length) + 'vw'
            }
        }
    }
    componentDidUpdate() {
        const list = this.props.entranceList.filter(entrance => JSON.parse(entrance.url)[this.props.from as FROM] !== '' && entrance.delFlag == 1)
        if (this.state.thumeStyle?.width !== (4 * 10 / list.slice(0, Math.ceil(list.length / 2)).length) + 'vw') {
            this.setState({
                thumeStyle: {
                    ...this.state.thumeStyle,
                    width: (4 * 10 / list.slice(0, Math.ceil(list.length / 2)).length) + 'vw'
                }
            })
        }
    }
    touchEnd() {
        const that = this;
        const list = this.props.entranceList.filter(entrance => JSON.parse(entrance.url)[this.props.from as FROM] !== '' && entrance.delFlag == 1)
        setTimeout(() => {
            if (scrollRef.current) {
                console.log(scrollRef.current.scrollLeft)
                // 获取一个icon槽位的宽度
                let barWidth = scrollRef.current.scrollWidth / list.slice(0, Math.ceil(list.length / 2)).length;
                // 获取近似位置
                const to = barWidth * Math.round(scrollRef.current?.scrollLeft / barWidth);
                that.scroll(to);
                that.barMove(Math.round(scrollRef.current?.scrollLeft / barWidth) * 10 / that.props.entranceList.slice(0, Math.ceil(that.props.entranceList.length / 2)).length);
            }
        }, 150);
    }
    barMove(left: number) {
        this.setState({
            thumeStyle: {
                ...this.state.thumeStyle,
                marginLeft: left + 'vw'
            }
        });
    }
    scroll(left: number) {
        var scrollSpeed = 1;//值越大，滚动的越慢
        const that = this;
        if (scrollRef.current && Math.abs(scrollRef.current.scrollLeft - left) > 3) {
            if (scrollRef.current.scrollLeft - left < 0) {
                scrollRef.current.scrollLeft += 3;
            } else {
                scrollRef.current.scrollLeft -= 3;
            }
            scrollTimeout = setTimeout(() => { that.scroll(left) }, scrollSpeed);
        } else if (scrollRef.current && Math.abs(scrollRef.current.scrollLeft - left) > 1) {
            if (scrollRef.current.scrollLeft - left < 0) {
                scrollRef.current.scrollLeft++;
            } else {
                scrollRef.current.scrollLeft--;
            }
            scrollTimeout = setTimeout(() => { that.scroll(left) }, scrollSpeed);
        } else if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
    }
    async aiRegist(name: string) {
        const that = this;
        let result = await get('/api/v1/faceRegister', {
            faceUrl: this.props.userInfo?.faceUrl,
            phone: this.props.userInfo?.phone
        })
        if (result.code === 0) {
            this.props.onLogin({ ...this.props.userInfo, trail: result.code }, this.props.userToken as string);
            await post('/api/basis/editUserInfo', { ...this.props.userInfo, trail: result.code });
            // this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aioth5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/' + encodeURIComponent(name))
            this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aiotXzh5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/' + encodeURIComponent(name) + '#/pages/ranking/index')
        } else if (result.code === 300 && result.message === "注册失败，您已使用其他手机号进行过注册") {
            // this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aioth5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/' + encodeURIComponent(name))
            this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aiotXzh5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/' + encodeURIComponent(name) + '#/pages/ranking/index')
        }
        else {
            setTimeout(function () {
                that.props.history.push('/userInfo');
            }, 1000);
        }
    }
    jump(entrance: string, title: string) {
        console.log(entrance);
        console.log(title);
        const that = this
        let urlMap = JSON.parse(entrance);
        let es = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (this.props.from !== null) {
            if (urlMap[this.props.from as FROM] === 'ai') {
                if (this.props.state === LOGINSTATE.SIGNIN) {
                    if (this.props.userInfo?.trail !== null) {
                        // ai步道已注册
                        // this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aioth5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/' + encodeURIComponent(title))
                        this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aiotXzh5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/' + encodeURIComponent(title) + '#/pages/ranking/index')
                    } else {
                        // ai步道注册
                        if (this.props.userInfo?.faceUrl !== null) {
                            // 有人脸
                            this.aiRegist(title);
                        } else {
                            // 没有人脸
                            Toast.show("请上传人脸图片");
                            setTimeout(function () {
                                that.props.history.push('/toAI/toAI');
                            }, 1000)
                        }
                    }
                } else {
                    localStorage.setItem('jumpLink', '/main/home')
                    // this.props.history.push('/accountLogin')
                    this.props.history.push('/main/home')
                }
                return;
            } else if (urlMap[this.props.from as FROM] === 'yygp') {
                if (localStorage.getItem('userToken') === null) {
                    localStorage.setItem('jumpLink', '/main/home')
                    // this.props.history.push('/accountLogin')
                    this.props.history.push('/main/home')
                    return;
                }
                if (this.props.from === FROM.APP) {
                    this.props.history.push('/OutURL/' + encodeURIComponent(`https://h5ticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=1`) + '/' + encodeURIComponent(title))
                } else if (this.props.from === FROM.H5 && window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
                    this.props.history.push('/OutURL/' + encodeURIComponent(`https://wxticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=1&memberId=${this.props.userInfo?.phone}`) + '/' + encodeURIComponent(title))
                }
                return;
            } else if (urlMap[this.props.from as FROM] === 'jdyy') {
                alert("跳转提示", "您即将跳转到‘涵田度假村小程序’", [
                    {
                        text: "确定",
                        onPress: () => {
                            window['jsGoToWxMini'].postMessage("{\"username\": \"gh_d6f0fa7afa9a\",\"path\": \"/pages/list/reserve/index\"}");
                        }
                    },
                    { text: "取消", onPress: () => console.log("cancel") },
                ]);
            } else if (urlMap[this.props.from as FROM] === 'gggp') {
                alert("跳转提示", "您即将跳转到‘观光车购票小程序’", [
                    {
                        text: "确定",
                        onPress: () => {
                            window['jsGoToWxMini'].postMessage("{\"username\": \"gh_125cfb490460\",\"path\": \"/pages/home/index/index.html\"}");
                        }
                    },
                    { text: "取消", onPress: () => console.log("cancel") },
                ]);
            } else if (urlMap[this.props.from as FROM] === 'ar') {
                if (this.props.state === LOGINSTATE.SIGNIN) {
                    if (this.props.from === FROM.APP) {
                        window['jsJumpAr'].postMessage("");
                    }
                } else {
                    // this.props.history.push('/main/mine')
                    localStorage.setItem('jumpLink', '/main/home')
                    // this.props.history.push('/accountLogin')
                    this.props.history.push('/main/home')
                }
                return;
            }
            if (es.test(urlMap[this.props.from as FROM])) {
                // if(this.props.from === FROM.APP) {
                //     window['jsJumpUrl'].postMessage(urlMap[this.props.from as FROM]);
                // } else {
                //     window.location.href = urlMap[this.props.from as FROM]
                // }
                this.props.history.push('/OutURL/' + encodeURIComponent(urlMap[this.props.from as FROM]) + '/' + encodeURIComponent(title))
            } else {
                this.props.history.push(urlMap[this.props.from as FROM]);
            }
        }
    }
    render() {
        const list = this.props.entranceList.filter(entrance => JSON.parse(entrance.url)[this.props.from as FROM] !== '' && entrance.delFlag == 1)
        const line1 = list.slice(0, Math.ceil(list.length / 2));
        console.log(line1)
        const line2 = list.slice(Math.ceil(list.length / 2), list.length);
        console.log(line2)
        const { thumeStyle } = this.state;
        return (
            <div className={style.Entrance}>
                <div
                    className={style.EntranceScrollbar}
                    ref={scrollRef}
                    onTouchEnd={() => this.touchEnd()}
                >
                    <div className={style.EntranceLine}>
                        {line1.map((entrance: IEntrance, index: number) => {
                            if (JSON.parse(entrance.url)[this.props.from as FROM] === 'yygp' && this.props.from === FROM.H5 && window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) != 'micromessenger') {
                                // 普通浏览器的h5不显示
                                return null;
                            } else {
                                return (
                                    <div className={style.EntranceBox} key={index} onClick={() => this.jump(entrance.url, entrance.title)}>
                                        <div className={style.icon}>
                                            {JSON.parse(entrance.icon).name === 'upload' ?
                                                <img className={style.entryImg} src={JSON.parse(entrance.icon).h5ViewBox} alt="" /> :
                                                <SvgLog
                                                    name={JSON.parse(entrance.icon).name}
                                                    width="100%"
                                                    height="100%"
                                                    color={''}
                                                    viewBox={JSON.parse(entrance.icon).h5ViewBox}
                                                ></SvgLog>}
                                        </div>
                                        <div className={style.title}>{entrance.title}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className={style.EntranceLine}>
                        {line2.map((entrance: IEntrance, index: number) => {
                            if (JSON.parse(entrance.url)[this.props.from as FROM] === 'yygp' && this.props.from === FROM.H5 && window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) != 'micromessenger') {
                                // 普通浏览器的h5不显示
                                return null;
                            } else {
                                return (
                                    <div className={style.EntranceBox} key={index} onClick={() => this.jump(entrance.url, entrance.title)}>
                                        <div className={style.icon}>
                                            {JSON.parse(entrance.icon).name === 'upload' ?
                                                <img className={style.entryImg} src={JSON.parse(entrance.icon).h5ViewBox} alt="" /> :
                                                <SvgLog
                                                    name={JSON.parse(entrance.icon).name}
                                                    width="100%"
                                                    height="100%"
                                                    color={''}
                                                    viewBox={JSON.parse(entrance.icon).h5ViewBox}
                                                ></SvgLog>
                                            }
                                        </div>
                                        <div className={style.title}>{entrance.title}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                <div className={style.scrollbar}>
                    <div className={style.thume} style={thumeStyle}></div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter<any, ComponentClass<IProps>>(Entrance));