import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Router,
  Redirect,
  withRouter,
  HashRouter,
  RouteComponentProps,
} from "react-router-dom";
import { Routers } from "./routers";
// import Content from './components/Content';
import "./App.css";
import Navbar from "./components/Navbar";
import { LoginInfo, UserInfo } from "./store/type";
import { FROM, LOGINSTATE } from "./store/enmu";
import { getPadding, login, setFrom, setTop, signout } from "./store/actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { breadcrumbNameMap } from "./components/routerList";
import { Button, Toast } from "antd-mobile";
import OptionToast from "./components/OptionToast";
import ImportComponent from "./components/ImportComponent";
import { message } from "antd";
import { loginInfo } from "./store/reducter";

interface IProps extends RouteComponentProps, LoginInfo {
  onSignout: () => {};
  getPadding: (padding: string) => void;
  setFrom: (from: string) => void;
  setTop: (top: string) => void;
  login: (useInfo: UserInfo, token: string) => void;
}
const mapStateToProps = (
  state: LoginInfo
): {
  padding?: string;
  top?: string;
} => ({
  padding: state.padding,
  top: state.top
});
const redirectUrl: string[] = ['ticketreplace', 'GZHRedirect', 'phoneLogin', 'accountLogin', 'register']
const ticketURL: string[] = ['OutURL', 'invoice']
const reservationURL: string[] = ['reservation', 'reservationList', 'reservationDetail', 'reservation', 'OutURL']

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPadding: (padding: string) => dispatch(getPadding(padding)),
  setFrom: (from: FROM) => dispatch(setFrom(from)),
  setTop: (top: string) => dispatch(setTop(top)),
  login: (useInfo: UserInfo, token: string) => dispatch(login(useInfo, token)),
  onSignout: () => dispatch(signout()),
});
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
const WeixinJSBridge = window["WeixinJSBridge"];
const divRef = React.createRef<HTMLDivElement>();
function wxListen() {
  if (
    WeixinJSBridge &&
    typeof WeixinJSBridge == "object" &&
    typeof WeixinJSBridge.invoke == "function"
  ) {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if ((document as { attachEvent?: Function }).attachEvent) {
      (document as unknown as { attachEvent: Function }).attachEvent(
        "WeixinJSBridgeReady",
        handleFontSize
      );
      (document as unknown as { attachEvent: Function }).attachEvent(
        "onWeixinJSBridgeReady",
        handleFontSize
      );
    }
  }
  function handleFontSize() {
    if (WeixinJSBridge && WeixinJSBridge.invoke) {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on("menu:setfont", function () {
        WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
      });
    }
  }
}
function htmlFontSize(scale: number) {
  document.getElementsByTagName("html")[0].style.cssText = 'font-size: calc(' + 1 / scale + 'vw);overflow: hidden;line-height: 1vw';
  // document.documentElement.style.fontSize = 'calc('+1/scale+'vw)';
}
var initViewport = function (height: string) {
  var metaEl = document.querySelector("#viewportMeta");
  var content = "width=device-width,initial-scale=1.0,user-scalable=no,maximum-scale=1.0,height=" + height;
  if (metaEl !== null) {
    metaEl.setAttribute('name', 'viewport');
    metaEl.setAttribute('content', content);
  }
}
var addEvenListener = function (callback: (e: any) => void, eventName: string) {
  let addEvenListenerFn = window.addEventListener || window.attatchEvent;
  let messageEvent = window.addEventListener ? eventName : "on" + eventName;
  addEvenListenerFn(messageEvent, callback, false);
}
function App(props: IProps) {
  if (ticketURL.indexOf(props.history.location.hash.replace('#', '').split('/')[1]) === -1) {
    // 离开门票开票功能页面是清理callback页面
    localStorage.removeItem('ticketCallback')
  }
  if (reservationURL.indexOf(props.history.location.hash.replace('#', '').split('/')[1]) === -1) {
    localStorage.removeItem('readKnow')
  }
  if (redirectUrl.indexOf(props.history.location.hash.replace('#', '').split('/')[1]) === -1) {
    localStorage.removeItem('redirectURL')
    localStorage.removeItem('noLoginURL')
  }
  addEvenListener(function (e) {
    let target = e.target;
    // console.log(e)
    setTimeout(() => {
      if (target.tagName.toLowerCase() === 'input') {
        var reg = /^(number|text|password)$/
        // console.log(target.type)
        if (reg.test(target.type)) {
          props.setTop(`${e.touches[0].clientY}`);
        } else {
          props.setTop('0');
        }
      } else {
        props.setTop('0');
      }
    }, 200)
  }, 'touchstart')
  addEvenListener(function () {
    setTimeout(function () {
      if (window['windowSizeSave'] && Math.abs(window.innerHeight - window.windowSizeSave.height) > 0.3 * window.innerHeight) {
        initViewport(window.windowSizeSave.height);
      }
    }, 300)
  }, "orientationchange")
  if (!window.windowSizeSave) {
    try {
      window['setScreenInfo'] = function (msg: string) {
        try {
          window.windowSizeSave = {
            height: JSON.parse(msg).height,
            width: JSON.parse(msg).width
          };
        } catch (e) { }
      }
      window['getScreenInfo'].postMessage('');
    } catch (e) {
      window.windowSizeSave = {
        height: window.innerHeight,
        width: window.innerWidth
      }
    }
  }
  // addEvenListener(function(){
  //   window.windowSizeSave = {
  //     height:window.innerHeight,
  //     width:window.innerWidth
  //   }
  // },"load"); 
  addEvenListener(function (e) {
    setTimeout(function () {
      if (window['windowSizeSave'] && Math.abs(window.innerHeight - window.windowSizeSave.height) > 0.3 * window.innerHeight) {
        initViewport(window.windowSizeSave.height);
      }
      // console.log(props.top)
      if (window['windowSizeSave'] && Math.abs(window.innerHeight - window.windowSizeSave.height) > 0.5 * window.innerHeight) {
        if (divRef.current) {
          divRef.current.style.height = '60vh'
          divRef.current.scrollTop = Number(props.top) - window.innerHeight * 0.5;
        }
      } else {
        if (divRef.current) {
          divRef.current.style.height = '100vh'
        }
      }
    }, 200);
  }, "resize");
  wxListen();
  let title = breadcrumbNameMap['/' + props.history.location.hash.replace('#', '').split('/').map(path => Number.isNaN(Number(path)) ? path : '').filter(path => path !== '').join('/')];
  document.title = title ? title : '徐州园博园'
  window['onBackButtonClick'] = function () {
    if (props.history.location.hash.replace('#', '') === '/accountLogin' || props.history.location.hash.replace('#', '') === '/phoneLogin') {
      props.history.go(-2)
    } else if (props.history.location.hash.replace('#', '').split('/')[1] === 'main') {
      window['closeApp'].postMessage("");
    } else if (props.history.location.pathname.split('/')[3] && props.history.location.pathname.split('/')[1] === 'invoice' && props.history.location.pathname.split('/')[3] === 'ticket') {
      switch (props.from) {
        case FROM.APP:
          props.history.replace(`/OutURL/${encodeURIComponent(`https://h5ticket.xzyby.cn/Ticket/MyOrder?memberId=${props.userInfo?.phone}&openId=${props.userToken}&type=2`)}/我的订单`)
          break;
        case FROM.H5:
          props.history.replace(`/OutURL/${encodeURIComponent(`https://wxticket.xzyby.cn/Ticket/MyOrder?memberId=${props.userInfo?.phone}&openId=${props.userToken}&type=2`)}/我的订单`)
          break;
        case FROM.WXMINI:
          props.history.replace(`/OutURL/${encodeURIComponent(`https://ticket.xzyby.cn/Ticket/MyOrder?memberId=${props.userInfo?.phone}&openId=${props.userToken}&type=2`)}/我的订单`)
          break;
      }
    } else {
      props.history.go(-1)
    }
  }
  window['appGetToken'] = function () {
    return localStorage.getItem('userToken')
  }
  window['tokenOutTime'] = function () {
    props.onSignout();
    // window.location.href = window.location.href.split('#')[0] + "#/accountLogin";
    window.location.href = window.location.href.split('#')[0] + "#/main/home";
  }
  window['setPadding'] = function (msg: string) {
    props.getPadding(msg);
    if (!JSON.parse(msg).textscale) {
      Toast.info('请下载最新版app')
    }
    window["hasPadding"] = true;
  };
  if (!window["hasPadding"] && window["getPadding"]) {
    window["getPadding"].postMessage("");
  }
  if (window["getPadding"]) {
    props.setFrom(FROM.APP);
  } else if (
    navigator.userAgent &&
    navigator.userAgent.indexOf("miniProgram") > -1
  ) {
    props.setFrom(FROM.WXMINI);
  } else {
    props.setFrom(FROM.H5);
  }
  // if (FROM.H5 == 'h5' && navigator.userAgent.toLowerCase().indexOf('micromessenger') == -1) {
  //   // 这里警告框会阻塞当前页面继续加载
  //   // alert('已禁止本次访问!');
  //   // 以下代码是用javascript强行关闭当前页面
  //   var opened = window.open('about:blank', '_self');
  //   opened.opener = null;
  //   opened.close();
  // }
  // console.log(navigator.userAgent);
  const Apptop = JSON.parse(props.padding ? props.padding : "{}");
  if (Apptop.textscale) {
    htmlFontSize(Apptop.textscale);
  } else {
    htmlFontSize(1);
  }
  useEffect(() => {
    try {
      if (window['jsShowWebInfo'])
        window['jsShowWebInfo'].postMessage('');
      else {
        let interval = setInterval(() => {
          if (window['jsShowWebInfo']) {
            window['jsShowWebInfo'].postMessage('');
            clearInterval(interval);
          }
        }, 1000)
      }
    } catch (e: any) { }
  }, [])
  return (
    <div className="App" ref={divRef}>
      <ImportComponent />
      <HashRouter>
        <Switch>
          {Routers.map((router) => (
            !router.withProps ?
              <Route
                exact={!router.notExect}
                key={router.key}
                path={router.path}
                component={router.component}
              ></Route> :
              <Route
                exact={!router.notExect}
                key={router.key}
                path={router.path}
                render={() => (
                  <router.component {...router.settings} />
                )}
              ></Route>
          ))}
          <Redirect path="/" to="/main" />
        </Switch>
        {/* <Navbar/>
      <Switch>
        {
          Routers.map(router => (
            <Route
              exact={router.notExect}
              key={router.key}
              path={router.path}
              component={router.component}
            >
            </Route>
          ))
        } */}
        {/* 设置默认路由 推荐方法一*/}
        {/* 方法一 */}
        {/* <Route path="/" component={Home} exact></Route> */}
        {/* 方法二 重定向*/}
        {/* <Redirect path="/" to="/home" /> */}
        {/* </Switch> */}
      </HashRouter>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, FunctionComponent<IProps>>(App));
